html, body {
  margin: 0px;
  padding: 0px;
}

.dark {
  background-color: #000000;
}

.light {
  background-color: #ffffff;
}

.navy {
  background-color: #111a24;
}
